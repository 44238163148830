<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                rounded
                dark
                v-bind="attrs"
                v-on="on"
                v-text="'Welcome'"/>
        </template>
        <v-list>
            <v-list-item v-if="$auth.user().roles && $auth.user().roles.includes('admin')" :to="{name: 'admin.index'}">
                <v-list-item-title>Administrator</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{name: dashboardLink}">
                <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>
            <v-list-item  @click="handleLogout">
                <v-list-item-title class="cursor-pointer">Logout</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'auth-user-dropdown',
    computed: {
        dashboardLink() {
            return 'dashboard.index'
        },
        auth() {
            return this.$auth
        }
    },
    data() {
        return {}
    },
    methods: {
        handleLogout() {
            this.$auth.logout()
        },
    }
}
</script>

<style scoped>

</style>
