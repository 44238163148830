Number.prototype.formatCurrency = function (withSignAndFraction = true) {
    const options = {};

    if (withSignAndFraction) {
        options.style = 'currency';
        options.currency = 'USD';
    }

    return new Intl.NumberFormat('en-EN', options).format(this);
};
