
export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans',
        name: 'admin.price-plans.index',
        component: () => import('../views/admin/price-plans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/create',
        name: 'admin.price-plans.create',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/:id',
        name: 'admin.price-plans.edit',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/create',
        name: 'admin.users.create',
        component: () => import('../views/admin/users/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.edit',
        component: () => import('../views/admin/users/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/faq/',
        name: 'admin.faq.index',
        component: () => import('../views/admin/faq/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/faq/:id',
        name: 'admin.faq.edit',
        component: () => import('../views/admin/faq/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/faq/create',
        name: 'admin.faq.create',
        component: () => import('../views/admin/faq/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },

    {
        path: '/admin/contents',
        name: 'admin.content.index',
        component: () => import('../views/admin/contents/Index'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/contents/create',
        name: 'admin.content.create',
        component: () => import('../views/admin/contents/Form'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/contents/:id',
        name: 'admin.content.edit',
        component: () => import('../views/admin/contents/Form'),
        meta: {
            auth: 'admin'
        }
    },
];
