<template>
    <div class="wrapper">
        <div class="header__wrapper">

            <div class="header__logo">
                <a href="/">
                    <img src="../../assets/img/logomain.svg" alt="Convert my file" class="header__logo-img">
                </a>
            </div>

            <nav :class="{show: showedMenu}" class="header__nav align-center">
                <ul class="header__list">
                    <li v-for="item in items" :key="item.name" class="header__item">
                        <router-link v-if="item.vue_router" :to="item.link" class="header__link text-style">{{ item.name }}</router-link>
                        <a v-else :href="item.link" class="header__link text-style">{{ item.name }}</a>
                    </li>
                </ul>

                <div class="header__active">
                    <router-link v-if="$auth.user()" :to="{name: 'my-files'}">
                        <button class="header__my-files text-style">
                            My files <span style="color: #5B7FFF">({{ files.length || '0' }})</span>
                        </button>
                    </router-link>

                    <auth-user-dropdown v-if="$auth.user()" />

                    <template v-if="!$auth.user()">
                        <router-link :to="{name: 'faq.requests.new'}" class="text-decoration-none text-style mr-10">
                            <v-icon large color="darken-2">
                                mdi-chat-question-outline
                            </v-icon>
                        </router-link>
                        <router-link
                            :to="{name: 'login'}"
                            class="text-decoration-none text-style mr-10"
                            v-text="'Login'"/>
                        <router-link
                            v-if="$route.name !== 'pricing'"
                            :to="{name: 'pricing'}"
                            class="text-decoration-none text-style"
                            v-text="'Sign Up'"/>
                    </template>
                </div>
            </nav>

            <div @click.prevent="showedMenu = !showedMenu" :class="{showedMenu: showedMenu}" class="header__toggleMenu">
                <v-icon size="40"> {{ showedMenu ? 'mdi-close' : 'mdi-menu'}}</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthUserDropdown from "../AuthUserDropdown";
    import {mapState} from 'vuex'

    export default {
        name: "app-header",
        components: {AuthUserDropdown},
        data: function () {
            return {
                showedMenu: false,
                formatGroups: [],
                items: [
                    {
                        name: "OCR",
                        vue_router: true,
                        link: {name: 'ocr'},
                    },
                    {
                        name: "About",
                        vue_router: true,
                        link: {name: 'supported-formats'},
                    },
                    {
                        name: "API",
                        vue_router: true,
                        link: {name: 'api'},
                    },
                    {
                        name: "Pricing",
                        vue_router: true,
                        link: {name: 'pricing'},
                    },
                    {
                        name: "Help",
                        vue_router: true,
                        link: {name: 'faq'},
                    }
                ]
            }
        },
        computed: {
            ...mapState({
                files: state => {
                    return state.myFiles.items;
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.header {
    //&__active,
    &__wrapper {
        display: flex;
        flex-wrap: nowrap;
    }
    &__nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-left: 4rem;

        &.show {
            display: block !important;
        }
        @media (max-width: 1300px) {
            a {
                font-size: 1.1rem;
            }
            .header__item {
                margin-right: 2rem;
            }
        }

        @media (max-width: 1000px) {
            .header__item {
                margin-right: 1.5rem;
            }
        }

        @media (max-width: 900px) {
            display: none;
            margin-left: 0;
            z-index: 9999;
            padding: 2rem 0;
            position: fixed;
            width: 100%;
            height: 100%;
            background: white;
            left: 0;
            top: 0;

            .header__list {
                flex-direction: column;
                width: 100%;
                background: white;
                & > li {
                    margin-right: 0;
                    a {
                        display: block;
                        margin: 10px 20px;
                        border-bottom: 1px solid var(--v-secondary-lighten5);
                    }
                }
            }

            .header__active {
                flex-direction: column;
                padding: 20px;
                background: white;

                a {
                    display: block;
                    width: 100%;
                    border-radius: 6px;
                    border: 1px solid var(--v-primary-base);
                    text-align: center;
                    padding: .5rem 1rem;
                    margin-top: 1rem;
                    margin-left: 40px;
                    margin-right: 40px;
                    //&.mr-10 {
                    //    margin-right: 1rem !important;
                    //}
                }
            }
        }
    }

    &__toggleMenu {
        display: none;
        color: black;
        z-index: 99999;

        &.showedMenu {
            position: fixed;
            top: 20px;
            right: 20px;
        }
        @media (max-width: 900px) {
            display: block;
        }
    }
}
</style>
