import Home from "../views/Home";
import isSubscribed from "./middleware/isSubscribed";

export  default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'File converter'
        }
    },
    {
        path: '/formats/:format',
        name: 'convert-formats',
        component: () => import('../views/convert/Formats.vue'),
        meta: { }
    },
    {
        path: '/supported-formats',
        name: 'supported-formats',
        component: () => import('../views/navigation/SupportedFormats.vue'),
        meta: {
            title: 'Supported formats'
        }
    },
    {
        path: '/converters/:group',
        name: 'converters-group',
        component: () => import('../views/convert/ConverterGroups.vue'),
        meta: { }
    },
    {
        path: '/my-files',
        name: 'my-files',
        component: () => import('../views/my-files/Index.vue'),
        meta: {
            title: 'My files'
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/navigation/About.vue'),
        meta: {
            middleware: [
                isSubscribed
            ]
        }
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('../views/navigation/Pricing.vue'),
        meta: {
            title: 'Pricing'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/navigation/FAQ.vue'),
        meta: {
            title: 'FAQ'
        }
    },
    {
        path: '/faq/:id',
        name: 'faq.show',
        props: true,
        component: () => import('../views/navigation/FaqArticle.vue'),
        meta: {
            title: 'FAQ'
        }
    },
    {
        path: '/faq/search/:query',
        name: 'faq.search',
        props: true,
        component: () => import('../views/navigation/FaqSearch.vue'),
        meta: {
            title: 'Search from FAQ'
        }
    },
    {
        path: '/faq/requests/new',
        name: 'faq.requests.new',
        props: true,
        component: () => import('../views/navigation/FaqNewRequest.vue'),
        meta: {
            title: 'Ask a Question'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/sign-up/:pricePlan',
        name: 'sign-up',
        props: true,
        component: () => import('../views/SignUp.vue'),
        meta: {
            title: 'Sign up'
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
            auth: false,
            title: 'Forgot password'
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Reset password'
        }
    },
    {
        path: '/403',
        name: '403',
        component: () => import('../views/static/403.vue'),
        meta: { }
    },
    {
        path: '/404',
        name: 'error-not-found',
        component: () => import('../views/static/404.vue'),
        meta: { }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../views/static/PrivacyPolicy.vue'),
        meta: {
            title: 'Privacy policy'
        }
    },
    {
        path: '/convert',
        name: 'convert',
        component: () => import('../views/Convert.vue'),
        meta: {
            title: 'Convert'
        }
    },
    {
        path: '/conversion-api',
        name: 'api',
        component: () => import('../views/api/Index'),
        meta: {
            title: 'Conversion API'
        }
    },
    {
        path: '/conversion-api/pricing/',
        name: 'api.pricing',
        component: () => import('../views/api/Pricing'),
        meta: {
            title: 'API Pricing'
        }
    },
    {
        path: '/ocr',
        name: 'ocr',
        component: () => import('../views/ocr/Index.vue'),
        meta: {
            title: 'Free online OCR'
        }
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('../views/navigation/Terms.vue'),
        meta: {
            title: 'Terms of use'
        }
    },
]
