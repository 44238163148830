import authConfig from "../../configs/auth";

export default async function auth({next}) {
    const token = localStorage.getItem(authConfig.authTokenName) || localStorage.getItem('access_token')

    if (!token) {
        return next({ name: 'login' })
    }
    return next()
}
