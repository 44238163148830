<template>
    <v-main class="main">
        <top-menu />
        <slot> <!--CONTENT--> </slot>
        <app-footer/>
    </v-main>
</template>

<script>
    import TopMenu from "../components/TopMenu";
    import AppFooter from "../components/AppFooter";

    export default {
        name: 'front-layout',
        components: {
            AppFooter,
            TopMenu
        },
        data () {
            return {}
        },
    }
</script>

<style scoped>

</style>
