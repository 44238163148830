<template>
    <v-app>
        <router-view/>
        <saas-snackbar />
    </v-app>
</template>

<script>
    import SaasSnackbar from "./components/SaasSnackbar";

    export default {
        name: 'App',
        components: {
            SaasSnackbar,
        },
        data() {
            return {}
        },
        computed: {
            layout() {
                return `${(this.$route.meta.layout || 'empty')}-layout`
            },
        },
    };
</script>
<style type="text/css">
    @import './assets/css/style.css';

    #nprogress .bar {
        height: 4px !important;
    }
</style>
