<template>
    <v-row>
        <v-col :cols="cols" v-for="(item, index) in contents" :key="index">
            <slot name="item" :item="item"/>
        </v-col>
    </v-row>
</template>

<script>
import Content from "@/models/Content";

export default {
    name: 'ContentCardGroup',
    props: {
        scopes: {
            type: Array,
            required: true
        },
        cols: {
            type: Number,
            default: 4
        }
    },
    data() {
        return {
            contents: []
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        async fetch() {
            const content = Content

            this.scopes.forEach((scope) => content.where('scopes', scope))

            this.contents = await content
                .whereIn('scopes', this.scopes)
                .where('is_active', true)
                .get();
        }
    }
}
</script>

<style scoped>

</style>
