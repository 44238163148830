<template>
    <footer class="app-footer py-5">
        <v-container>
            <v-row>
                <v-col>
                    <div class="app-footer-title">Converted Files:</div>
                    <div class="d-flex">
                        <div class="app-footer-files-count">
                            {{ statistics.totalAmount }}
                        </div>
                        <div class="app-footer-size-count ml-1">
                            <span class="color-red">/</span>
                            {{ statistics.fileSize }} Mb
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col v-for="(col, idx) in $_.chunk(menu, menu.length / 2)" :key="idx">
                            <ul>
                                <li v-for="(item, colIdx) in col" :key="colIdx">
                                    <router-link :to="item.to" v-text="item.text"/>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
import FooterMenu from '../components/public/footer-menu'
import Api from "../api/Api";

export default {
    name: 'AppFooter',
    data() {
        return {
            menu: FooterMenu,
            statistics: {}
        }
    },
    mounted() {
        this.getStatistics();
    },
    methods: {
        async getStatistics() {
            this.statistics = await Api.statistics();
        }
    }
}
</script>

<style lang="scss" scoped>
.app-footer {
    background: var(--v-secondary-darken2);
    color: var(--v-secondary-lighten3);

    a {
        color: var(--v-secondary-lighten3);
        text-decoration: none;
    }

    .color-red {
        color: var(--v-error-base);
    }

    &-files-count {
        font-size: 1.8rem;
    }
}
</style>
