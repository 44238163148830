import axios from "axios";

export default {
    async fetchList() {
        let formats = await axios.get('/api/formats/list')
        return formats.data;
    },
    async supportedConvertFormats(format) {
        let formats = await axios.get('/api/formats/supportedConvertFormats', {
            params: {
                'forFormat': format
            }
        })
        return formats.data;
    },
    async groupNames() {
        let groups = await axios.get('/api/formats/groups')
        return groups.data;
    },
    async groupFormats(group) {
        let formats = await axios.get('/api/formats/groupFormats', {
            params: {
                group
            }
        })
        return formats.data;
    }
}
