import axios from "axios";

export default {

    /** Auth **/
    async forgotPassword(email) {
        return window.axios.post('/api/forgot-password', {email})
    },

    async resetPassword(params) {
        return window.axios.post('/api/reset-password', params)
    },

    async authWith(entity = 'facebook') {
        return window.axios.get('/api/auth/' + entity, {params: {back_url: window.location.href}})
    },

    async userAnalytics() {
        const response = await window.axios.get('api/users/analytics');

        return response?.data;
    },

    async statistics() {
        const response = await window.axios.get('api/statistics');

        return response?.data;
    },

    async generateToken() {
        const response = await axios.post('/api/sanctum/tokens/generate');

        return response?.data?.token
    },
}
