import MyFiles from "../api/MyFiles";
import {isAuthenticated} from '../router/middleware/isAuthenticated'

const state = {
    items: [],
};

const getters = {

};

const mutations = {
    SET_FILES(state, files) {
        state.items = files
    },
};

const actions = {
    async pushMultipleFiles({commit}, params) {
        let files = this.state.myFiles.items;

        for (let key in params.files) {
            files.unshift(params.files[key]);
        }

        if (await isAuthenticated()) {
            await MyFiles.sync(files)
        }

        await localStorage.setItem('my-files', JSON.stringify(files))
        commit('SET_FILES', files)
    },

    async fetchFiles({commit}) {
        let files = JSON.parse(localStorage.getItem('my-files')) || []

        if (await isAuthenticated()) {
            let response =  await MyFiles.fetchFiles()
            files = response.data
        }

        await localStorage.setItem('my-files', JSON.stringify(files))
        commit('SET_FILES', files)
    },

    async truncate({commit}) {
        commit('SET_FILES', [])
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}
