export default async function isSubscribed({ next }) {
    const user = JSON.parse(localStorage.getItem('user'))

    if (!user) {
        return next({ name: 'pricing' })
    }

    if (!user.price_plan_id && !user.roles?.includes('admin')) {
        return next({
            name: 'pricing'
        })
    }

    return next()
}
