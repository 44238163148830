<template>
    <div>
        <v-app-bar v-if="isDashboard" app clipped-left>
            <v-app-bar-nav-icon @click="$emit('toggleDrawer')"/>
            <app-header/>
        </v-app-bar>

        <header class="header" v-else>
            <app-header />
        </header>
    </div>
</template>

<script>
    import AppHeader from './header/AppHeader.vue';

    export default {
        name: "top-menu",
        props: {
            isDashboard: {
                default: false,
                type: Boolean
            }
        },
        data: function () {
            return {

            }
        },
        components: {AppHeader}
    }
</script>

<style scoped>

</style>
