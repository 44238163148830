import axios from "axios";

export default {
    async sync(files) {
        return axios.post('/api/my-files/sync', { files })
    },
    async fetchFiles() {
        return axios.get('/api/my-files/fetch')
    }
}
