<template>
    <front-layout>
        <main>
            <section class="intro">
                <div class="wrapper">
                    <p class="intro__subtitle text-style">Online File Converter</p>
                    <h1 class="intro__title text-style">convert your files to<br> any format in <span style="color: #5B7FFF">3 clicks</span></h1>

                    <div class="form-container">
                        <div class="intro__documents-audio">
                            <div class="intro__documents">
                                <div class="intro__documents-behind"></div>
                                <div class="intro__documents-icon">
                                    <img src="../assets/img/documents-icon.svg" />
                                </div>
                                <div class="intro__documents-text text-style">Documents</div>
                            </div>

                            <div class="intro__audio">
                                <div class="intro__audio-behind"></div>
                                <div class="intro__audio-icon">
                                    <img src="../assets/img/audio-icon.svg" />
                                </div>
                                <div class="intro__audio-text text-style">Audio</div>
                            </div>
                        </div>

                        <div class="intro__images-video">
                            <div class="intro__images">
                                <div class="intro__images-behind"></div>
                                <div class="intro__images-icon">
                                    <img src="../assets/img/images-icon.svg" />
                                </div>
                                <div class="intro__images-text text-style">Images</div>
                            </div>

                            <div class="intro__video">
                                <div class="intro__video-behind"></div>
                                <div class="intro__video-icon">
                                    <img src="../assets/img/video-icon.svg" />
                                </div>
                                <div class="intro__video-text text-style">Video</div>
                            </div>
                        </div>

                        <div class="dropzone-area">
                            <div class="dropzone-backlighting"></div>
                            <div class="dropzone-background">
                                <upload-form />
                            </div>

                            <div v-if="!$auth.user()" class="dropzone-small-text">
                                100 MB maximum file size or
                                <router-link :to="{name: 'pricing'}">
                                    <span class="text-decoration-underline" v-text="'Sign Up'"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="container">

                <ContentGroup :scopes="['home']">
                    <template v-slot:item="{ item }">
                        <ContentCard :item="item"/>
                    </template>
                </ContentGroup>
            </div>
        </main>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import UploadForm from "../components/UploadForm";
    import ContentGroup from "../components/content/ContentGroup";
    import ContentCard from "../components/content/ContentCard";

    export default {
        name: 'Home',
        components: {
            FrontLayout,
            UploadForm,
            ContentGroup,
            ContentCard
        },
        data: () => {
            return {
                descriptions: [
                    {
                        icon: 'mdi-cached',
                        title: '300+ formats supported',
                        text: 'We support more than 25600 different conversions between more than 300 different file formats. More than any other converter.'
                    },
                    {
                        icon: 'mdi-star-circle-outline',
                        title: 'Fast and easy',
                        text: 'Just drop your files on the page, choose an output format and click "Convert" button. Wait a little for the process to complete. We aim to do all our conversions in under 1-2 minutes.'
                    },
                    {
                        icon: 'mdi-cloud-upload-outline',
                        title: 'In the cloud',
                        text: 'All conversions take place in the cloud and will not consume any capacity from your computer.'
                    },
                    {
                        icon: 'mdi-account-cog-outline',
                        title: 'Custom settings',
                        text: 'Most conversion types support advanced options. For example with a video converter you can choose quality, aspect ratio, codec and other settings, rotate and flip.'
                    },
                    {
                        icon: 'mdi-security',
                        title: 'Security guaranteed',
                        text: 'We delete uploaded files instantly and converted ones after 24 hours. No one has access to your files and privacy is 100% guaranteed. Read more about security.'
                    },
                    {
                        icon: 'mdi-monitor-cellphone-star',
                        title: 'All devices supported',
                        text: 'Is browser-based and works for all platforms. There is no need to download and install any software.'
                    }
                ]
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>

.intro {
    &__documents-audio {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
    }

    &__images-video {
        position: absolute;
        bottom: 20%;
        width: 100%;
    }

    @media (max-width: 900px) {
        &__title {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

.form-container {
    position: relative;
    max-width: 1300px;
    margin: 0 auto;

    .intro__audio,
    .intro__documents,
    .intro__images,
    .intro__video {
        z-index: 99;
    }

    .dropzone-area {
        max-width: 700px;
    }
}
</style>
