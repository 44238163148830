<template>
    <v-container class="UploadForm" style="width: 100%; height: 100%; padding: 0">
        <v-row class="mx-0" justify="center">
            <v-list subheader v-show="canConvert">
                <v-subheader v-text="'Uploaded Files'"/>
                <v-list-item v-for="file in files" :key="file.url">
                    <v-list-item-avatar size="64">
                        <v-img :src="file.url"/>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="file.original_name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                        <v-select
                            label="To"
                            :items="extensions[file.url]"
                            v-model="file.to"
                            item-text="name"
                            item-value="name"
                            :menu-props="{ maxHeight: '200' }"
                            autocomplete
                        >
                            <template slot="selection" slot-scope="data">
                                {{ data.item }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"/>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"/>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-select>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-row>
        <v-row class="mx-0">
            <v-col class="text-center" v-show="canConvert">
                <v-btn @click="handleConvert">Convert</v-btn>
            </v-col>
        </v-row>
        <v-row class="mx-0" style="height: 100%">
            <v-col class="text-center px-0 py-0">
                <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    class="my-vue-dropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot=true
                    @vdropzone-error="handleError"
                    @vdropzone-success="handleSuccess"
                >
                    <div class="dropzone-custom-content">
                        <div class="dropzone-custom-image">
                            <img src="../assets/img/plus.svg" alt="Added file" />
                        </div>
                        <div class="dropzone-custom-text">Drag and drop your file</div>
                    </div>
                </vue-dropzone>
                <div class="dropzone-custom-icons">
                    <v-btn
                        icon
                        x-large
                        v-for="icon in icons"
                        :key="icon.name"
                        :style="{'color': active === icon.name ? '#1C2A5C' : '#7b7b7b'}"
                        @click="changeActive(icon.name)"
                    >
                        <v-icon>{{ icon.pic }}</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import vue2Dropzone from "vue2-dropzone";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Formats from "../api/Formats";
    import {mapMutations} from "vuex";

    export default {
        name: "UploadForm",
        components: {
            vueDropzone: vue2Dropzone
        },
        computed: {
            canConvert() {
                return this.files.length > 0
            }
        },
        data() {
            return {
                dropzoneOptions: {
                    url: '/api/upload-public',
                    thumbnailWidth: 250,
                    maxFilesize: 100,
                    maxFiles: 3,
                    headers: {
                        Authorization: null
                    }
                },
                files: [],
                types: [],
                extensions: {},
                icons: [
                    {
                        name: "Monitor",
                        pic: "mdi-desktop-mac"
                    },
                    {
                        name: "Drive",
                        pic: "mdi-google-drive"
                    },
                    {
                        name: "Link",
                        pic: "mdi-link-variant"
                    }
                ],
                active: "",
            }
        },
        async mounted() {
            await this.fetchFormats()
            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = 'Bearer ' + token
        },
        methods: {
            ...mapMutations(['showSnackBar']),

            handleSuccess(file, res) {
                let extension = res.original_extension;
                // todo move to config
                if (res.original_extension === 'jpg') {
                    extension = 'jpeg'
                }

                const files = this.types.find(type =>
                    !!Object.keys(type).find(format => format === extension)
                )

                if (files) {
                    this.files.push(res)
                    // Set available convert extensions for each file
                    this.files.forEach(file => {
                        this.$set(this.extensions, file.url, files[extension])
                    })
                } else {
                    this.showSnackBar({color: 'error', timeout: 3000, text: 'Unsupported format ' + extension})
                }
            },
            handleConvert() {
                const {files} = this

                // Validate file convert extension
                if (files.some(f => !f.to)) {
                    files.filter(f => !f.to).forEach(file => {
                        this.showSnackBar({
                            color: 'error',
                            timeout: 3000,
                            text: 'Need set convert extension for file:' + file.original_name
                        })
                    })
                    return false;
                }

                this.$router.push({name: 'convert', params: {files}})
            },
            async fetchFormats() {
                let formats = await Formats.fetchList()

                Object.keys(formats).forEach(group => {
                    this.types.push({header: group, ...formats[group]})
                })
            },
            changeActive(val) {
                this.active = val
            },
            handleError(file, message) {
                if (message.errors) {
                    Object.keys(message.errors).forEach(key => {
                        message.errors[key].forEach(error => {
                            this.showSnackBar({color: 'error', timeout: 3000, text: error})
                        })
                    })
                } else {
                    this.showSnackBar({color: 'error', timeout: 3000, text: message.message || 'Error'})
                }
            }
        }
    }
</script>

<style lang="scss">
.dropzone-area {
    position: relative;
    margin: 0 auto;
    /*width: 705px;*/
    min-height: 507px;
    text-align: center;
}

/* .dropzone-area {
    position: absolute;
    width: 705px;
    min-height: 507px;
    text-align: center;

    left:50%;
    transform:translate(-50%, -50%);
} */

.dropzone-backlighting {
    position: absolute;
    /*width: 705px;*/
    bottom: 30px;
    top: 32px;

    background: rgba(28, 42, 92, 0.14);
    filter: blur(40px);
    border-radius: 45px;
}

.dropzone-background {
    position: relative;
    /*width: 705px;*/
    min-height: 458px;

    background: #FFFFFF;
    border-radius: 25px;

    padding: 32px;
    margin-bottom: 30px;;
}

.my-vue-dropzone {
    /* width: 641px; */
    height: 100%;

    border: 2px dashed rgba(91, 127, 255, 0.47) !important;
    box-sizing: border-box !important;
    border-radius: 20px;

    padding-top: 72px !important;
}
</style>
