import isSubscribed from "./middleware/isSubscribed";
import auth from "./middleware/auth";

export default [
    {
        path: '/dashboard',
        name: 'dashboard.index',
        component: () => import('../views/dashboard/Index.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed,
                auth,
            ]
        }
    },
    {
        path: '/dashboard/profile',
        name: 'dashboard.profile',
        component: () => import('../views/dashboard/Profile.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed
            ]
        }
    },
    {
        path: '/dashboard/billing',
        name: 'dashboard.billing',
        component: () => import('../views/dashboard/billing/Index.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed
            ]
        }
    },
    {
        path: '/dashboard/billing/select-plan',
        name: 'dashboard.billing.select-plan',
        component: () => import('../views/dashboard/billing/SelectPlan.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed
            ]
        }
    },
    {
        path: '/dashboard/billing/checkout',
        name: 'dashboard.billing.checkout',
        component: () => import('../views/dashboard/billing/Checkout.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed
            ]
        }
    },
    {
        path: '/dashboard/api',
        name: 'dashboard.api.index',
        component: () => import('../views/dashboard/api/Index.vue'),
        meta: {
            auth: true,
            middleware: [
                isSubscribed
            ]
        }
    },
]
