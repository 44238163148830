<template>
    <v-card elevation="0" outlined class="text-center contentCard">
        <v-icon aria-hidden="false">
            {{ item.icon }}
        </v-icon>
        <v-card-title v-text="item.title" class="justify-center"/>
        <v-card-text v-text="item.text" class="justify-center text-center"/>
    </v-card>
</template>

<script>
export default {
    name: 'ContentCard',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.contentCard {
    .v-icon {
        display: block;
        margin-top: 1rem;
        font-size: 41px;
    }

    &.v-card {
        min-height: 232px;
    }
}
</style>
